<template>
  <v-container fluid>
    <h1 class="d-flex justify-center">You don't have permission to access this page</h1>
  </v-container>
</template>

<script>
export default {
  name: 'empty-page',
};
</script>
